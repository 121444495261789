export const environment = {
    production: false,
    environmentName: 'test',
    storagePrefix: 'mta_plus_test',
    title: 'Test',
    apiUrl: '//api.test.mta-gte.co.uk/api/v1',
    webPath: '//api.test.mta-gte.co.uk',
    localPath: 'https://test.mta-gte.co.uk',
    sentryDSN: null,
    accounts: {
        vat_rate: 20
    },
};
