import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'total'
})
export class TotalPipe implements PipeTransform {

  transform(items: any[], field: string): number {
    if (!items) return 0;
    return items.reduce((a, b) => Number(a) + Number((this.getFieldValue(b, field) || 0)), 0);
  }

  private getFieldValue(item: any, field: string): number {
    return field.split('.').reduce((a, b) => a && a[b], item);
  }

}
