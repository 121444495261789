import {Injectable, TemplateRef} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToastService
{
    public toasts: any[] = [];

    public success(textOrTemplate: string | TemplateRef<any>, header?: string, delay: number = 10000): void
    {
        this.show(textOrTemplate, {
            classname: 'bg-success text-light',
            delay: delay,
            autohide: true
        }, header);
    }

    public error(textOrTemplate: string | TemplateRef<any>, header?: string, delay: number = 10000): void
    {
        this.show(textOrTemplate, {
            classname: 'bg-danger text-light',
            delay: delay,
            autohide: true
        }, header);
    }

    public show(textOrTemplate: string | TemplateRef<any>, options: any = {}, header?: string): void
    {
        this.toasts.push({header, textOrTemplate, ...options});
    }

    public remove(toast: any): void
    {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    public clear(): void
    {
        this.toasts.splice(0, this.toasts.length);
    }
}
