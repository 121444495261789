import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMileage',
})
export class FormatMileagePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (value == null) {
      return '';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
